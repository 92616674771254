<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="title big">
            My Brick Garden!
          </div>
          <div class="desc">
            Your ultimate gateway to the world's most exclusive, private, and stunning islands. We believe in transforming dreams into reality, and if your dream is to own an isolated sanctuary, then you've found the right place. Our curated selection of idyllic private islands is bound to take your breath away, promising an unmatched lifestyle of privacy, tranquility, and spectacular beauty.
          </div>
          <router-link to="/profile/buy-island" v-if="isAuth" class="button">BUY A PRIVATE ISLAND</router-link>
          <router-link to="/registration" v-else class="button">BUY A PRIVATE ISLAND</router-link>
          <div class="desc absolute">Our website is designed to offer a unique, luxurious and safe experience for all our customers.</div>
        </div>
      </div>
      <div class="section section-2">
        <div class="wrapper">
          <div class="left">
            <img src="./../images/house.png" class="img"/>
          </div>
          <div class="right">
            <div class="title purple">Our Promise to You</div>
            <div class="desc">
              At My Brick Garden, we believe in more than just facilitating transactions. We strive to become your trusted partner in realizing your dream of owning a private island. Our commitment to you includes:
            </div>
            <ul class="list">
              <li class="item desc">
                <b>Exclusivity:</b> We offer a unique, curated portfolio of private islands from around the world, many of which are exclusive to our platform.
              </li>
              <li class="item desc">
                <b>Expert Guidance:</b> We're with you every step of the way, providing professional advice and support to ensure a seamless buying experience.
              </li>
              <li class="item desc">
                <b>Your Satisfaction:</b> Your dream is our mission. We put your satisfaction at the heart of everything we do.
              </li>
            </ul>
            <div class="desc">
              With My Brick Garden, you're not just buying an island, but partnering with a dedicated team committed to turning your dream into a reality.
            </div>
          </div>
        </div>
      </div>
      <div class="section section-3">
        <div class="wrapper">
          <div class="blocks">
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
          </div>
          <div class="left">
            <div class="title purple">Safe and Secure</div>
            <div class="desc">
              In the digital realm of Minecraft, My Brick Garden ensures a safe and secure process for acquiring your private island. We adhere to the game's rules and regulations, guarantee fair transactions, and uphold stringent privacy standards. Our commitment to your secure gaming experience continues even after the purchase, with reliable support and assistance. With us, you're investing not just in virtual land, but also in a stress-free gaming journey.
            </div>
          </div>
          <div class="right">
            <img src="./../images/guy.png" class="img"/>
          </div>
        </div>
      </div>
      <div class="section section-4">
        <div class="wrapper">
          <div class="blocks">
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
          </div>
          <div class="title">
            <span class="purple">Discover the Joy of Your Own</span> Minecraft Island
          </div>
          <ul class="list">
            <li class="item desc">
              <b>Creative Freedom:</b> Owning a private island in Minecraft opens the door to endless creative possibilities. You have the freedom to build, modify, and tailor the environment to your liking, allowing your architectural imagination to truly soar. Whether it's a fortress, a farm, or a peaceful retreat, you call the shots.
            </li>
            <li class="item desc">
              <b>Exclusivity & Privacy:</b> Just like in the real world, privacy can be a luxury in the virtual world. Owning a private island in Minecraft provides a secure and exclusive space for you to enjoy the game, away from the hustle and bustle of crowded servers.
            </li>
            <li class="item desc">
              <b>Community & Economy:</b> Private islands can be a great way to engage with the Minecraft community. You could host events, invite friends, or even develop an economy by trading resources and goods. Owning an island isn't just fun; it can be a central part of your Minecraft social life.
            </li>
          </ul>
          <div class="our-price">
            <div class="left">
              <img src="./../images/dog.png" class="img"/>
            </div>
            <div class="center">
              <div class="title big">Our Price</div>
            </div>
            <div class="right">
              <multiselect v-model="activeIsland" :options="$parent.islands" @select="changeIslandToBuy" placeholder="PRICING">
                <template slot="singleLabel" slot-scope="props">
                  <div class="desc">{{ props.option.title }}</div>
                  <div class="desc">{{ props.option.dimensions }}</div>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="flex">
                    <div class="left">
                      <div class="desc">{{ props.option.title }}</div>
                      <div class="desc">{{ props.option.dimensions }}</div>
                    </div>
                    <div class="right">
                      <div class="desc">{{ props.option.price }}</div>
                    </div>
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-5">
        <div class="wrapper">
          <div class="options-block" v-if="$parent.products">
            <div class="title">Options:</div>
            <div class="list">
              <div class="item" v-for="(item, i) in $parent.products.slice(0, 10)" :key="i">
                <OptionCard :item="item" :currency="currency"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import OptionCard from '../components/OptionCard.vue';

import Multiselect from 'vue-multiselect'

export default {
  name: 'Home',
  props: ['currency', 'isAuth'],
  components: {
    OptionCard,
    Multiselect
  },
  data: function() {
    return {
      imgDomain: '',
      activeIsland: {}
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    changeIslandToBuy() {
      if (this.isAuth) {
        this.$emit('changeIslandToBuy', this.activeIsland);
        this.$router.push({path: '/profile/buy-island'});
      } else {
        this.$router.push({path: '/registration'});
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>