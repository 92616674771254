<template>
	<div class="item-wrapper" v-if="item">
		<div class="preview">
			<img :src="imgDomain + item.image_url" class="img"/>
		</div>
		<div class="bottom">
			<div class="desc">{{item.title}}</div>
			<div class="price desc">
				<span>{{item.price}}<span class="currency">{{currency}}</span></span>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'OptionCard',
	props: ['item', 'currency'],
	data: function() {
		return {
			imgDomain: ''
		}
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		
	},
}
</script>
