<template>
  <main class="main page-inside faq-page text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">
            FAQ
          </div>
          <div class="content">
            <div class="title">What is MyBrickGarden.com?</div>
            <div class="desc">
              MyBrickGarden.com is a website where you can create and customize your own virtual Minecraft islands with a range of unique features, including vegetation, structures, water, climate, fauna, transportation, lighting, and custom skins.
            </div>
            <div class="title">What are the sizes of the islands that can be created?</div>
            <div class="desc">
              We offer three island sizes: a small size of 30x30 blocks, a medium size of 70x70 blocks, and a large size of 140x140 blocks.
            </div>
            <div class="title">How much does it cost to create an island and how can I proceed with it?</div>
            <div class="desc">
              Creating a small island costs 100 euros, a medium island costs 240 euros, and a large island costs 480 euros. You can create your island by selecting the desired size and customizing the options on our website. Afterward, you can proceed with the creation by making an online payment.
            </div>
            <div class="title">What customization options are available for the islands?</div>
            <div class="desc">
              We provide various customization options for your island, including vegetation, structures, water, climate, fauna, transportation, lighting, and custom mods.
            </div>
            <div class="title">Is it possible to modify the options on my island after it has been created?</div>
            <div class="desc">
              Certainly! You can modify the options on your island at any time. Simply log into your personal account and navigate to the "My Islands" section to make the desired changes.
            </div>
            <div class="title">How can I connect to my island in Minecraft?</div>
            <div class="desc">
               Once your island is created, we will provide you with a unique host and port that you can use to connect to your island within the Minecraft game.
            </div>
            <div class="title">What should I do if I encounter any issues or need support with my island?</div>
            <div class="desc">
              If you have any issues or require support regarding your island, please reach out to us at support@{{$parent.hostname}}. Our dedicated support team is available to assist you. We are committed to ensuring your experience with MyBrickGarden.com is as smooth as possible and will be more than happy to address any questions or concerns you may have.
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Faq',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
   
  }
}
</script>