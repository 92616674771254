<template>
	<header class="header">
        <div class="wrapper">
          <ul class="nav">
            <template  v-for="item in $parent.nav">
              <li class="nav__item" :key="item.title">
                <router-link class="desc" :to="item.path">
                  {{item.title}}
                </router-link>
              </li>
            </template>
          </ul>
          <div class="center">
            <router-link to="/" class="logo">
                <img src="./../images/logo.svg" class="img"/>
            </router-link>
          </div>
          <div class="right">
            <router-link to="/profile/my-islands" class="link-container" v-if="$parent.isAuth">
              <div class="icon">
                <img src="./../images/user.svg" class="img"/>
              </div>
            </router-link>
            <div class="link-container" v-else>
              <div class="desc hover-link" @click="openSignUp">
                <span>SIGN UP</span>
              </div>
              <div class="button small hover-link" @click="openSignIn">
                <span>SIGN IN</span>
              </div>
            </div>
          </div>
        </div>
    </header>
</template>

<script>

export default {
	name: 'Header',
  props: [],
	data: function() {
		return {
		}
	},
	methods: {
    openSignIn() {
      this.$router.push({path: '/login'});
    },
    openSignUp() {
      this.$router.push({path: '/registration'});
    }
	}
}
</script>
