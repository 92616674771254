<template>
  <div class="modal offer-modal top-modal">
    <div class="overlay"  @click="$parent.closeTopUpModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeTopUpModal" src="./../assets/close.svg"/>
      <div class="container">
        <div class="form-wrapper form-wrapper-anount" v-if="amountStep">
          <div class="title">Enter amount</div>
          <label>
            <div class="desc">Amount</div>
            <input type="text" placeholder="Amount" v-model="amount"/>
            <div class="title">{{$parent.currency}}</div>
          </label>
          <button class="button" @click="nextStep">Next</button>
        </div>
        <div class="form-wrapper" v-if="!amountStep">
          <div class="title">Enter contact information</div>
          <label>
            <div class="desc"><span class="red">*</span> First name:</div>
            <input type="text" placeholder="First name" v-model="name"/>
          </label>
          <label>
            <div class="desc"><span class="red">*</span> E-mail:</div>
            <input type="email" placeholder="E-mail" v-model="email"/>
          </label>
          <label>
            <div class="desc"><span class="red">*</span> Last name:</div>
            <input type="text" placeholder="Last name" v-model="surname"/>
          </label>
          <label>
            <div class="desc"><span class="red">*</span> Phone:</div>
            <input type="tel" placeholder="Phone" v-model="phone"/>
          </label>
          <label class="input-container">
            <div class="desc">*Country:</div>
            <Select2 class="select2 single" v-model="country" 
            :options="countryOptions" 
            @select="selectCountry($event)"
            placeholder="Country"
            />
          </label>
          <label>
            <div class="desc">*City:</div>
            <input type="text" placeholder="City" v-model="city"/>
          </label>
          <label>
            <div class="desc">*Address:</div>
            <input type="text" placeholder="Address" v-model="address"/>
          </label>
          <label>
            <div class="desc">*Post Code:</div>
            <input type="text" placeholder="Post Code" v-model="postCode"/>
          </label>
            <label class="chekbox-label">
              <div class="chekbox-label-wrapper">
                <input type="checkbox" name="terms" v-model="terms"/>
                <div class="checkbox"></div>
                <span class="title">I agree with </span>
                <a @click="goToPrivacy()" class="title">privacy policy </a>
                <span class="title"> and </span>
                <a @click="goToTerms()" class="title">terms and conditions</a>
              </div>
            </label>
           <div class="total">
              <div class="title price">Top Up: {{amount}} {{$parent.currency}}</div>
            </div>
          <button @click="submit" :class="['button', {'disabled': !requiredFieldsAreFilled}]">Top up</button>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Select2 from 'vue3-select2-component';
export default {
  name: 'TopUpModal',
  props: [],
  components: {
    Select2
  },
  data: function() {
    return {
      amount: '',
      amountStep: true,
      email: '',
      name: '',
      surname: '',
      phone: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      countryOptions: [],
      countryOptionsId: [],
    }
  },
  mounted() {
    this.getCounties();
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
    .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignIn();
      }
    })
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.amount && this.country && 
        this.city && this.address  
        && this.postCode && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          let arr = res.data.payload;
          let newCountries = arr.map(function(item) {
            return item['title'];
          });
          let newCountriesId = arr.map(function(item) {
            return item['id'];
          });
          this.countryOptionsId = newCountriesId;
          this.countryOptions = newCountries;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    submit() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "amount": this.amount,
        "country": this.countryId,
        "city": this.city,
        "address": this.address,
        "postCode": this.postCode
      }
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
      .then((res) => {
          window.location = res.data.redirect_url;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    nextStep() {
      if (this.amount) {
        this.amountStep = false
      }
      
    },
    goToTerms() {
      this.$parent.closeTopUpModal();
      this.$parent.goToTerms();
    },
    goToPrivacy() {
      this.$parent.closeTopUpModal();
      this.$parent.goToPrivacy();
    }
  }
}
</script>