<template>
	<footer class="footer">
      <div class="footer-section">
        <div class="wrapper">
          <div class="left">
            <div v-html="$parent.settings.requisites" class="desc">
            </div>
            <div v-if="$parent.settings.copyright" class="desc">
              © {{ (new Date()).getFullYear() }} {{ $parent.settings.copyright }}
            </div>
          </div>
          <div class="right">
            <div class="top">
              <ul class="nav">
                <template  v-for="item in $parent.nav">
                  <li class="nav__item" :key="item.title">
                    <router-link :to="item.path">
                      {{item.title}}
                    </router-link>
                  </li>
                </template>
                <template  v-for="item in $parent.textPageList">
                  <li class="nav__item" :key="item.id">
                    <a @click="$parent.goToPage(item)">
                      {{item.title}}
                    </a>
                  </li>
                </template>
              </ul>
            </div>
            <div class="bottom">
              <ul class="list">
                <li class="item">
                  <img src="./../images/mc_symbol.svg" class="img"/>
                </li>
                <li class="item">
                  <img src="./../images/visa.svg" class="img"/>
                </li>
                <li class="item">
                  <img src="./../images/3ds.png" class="img"/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
		
	},
	mounted() {
    
  }
}
</script>
