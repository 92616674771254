<template>
  <div class="modal offer-modal ready-modal-products">
    <div class="overlay"  @click="$parent.closeReadyModalProducts"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeReadyModalProducts" src="./../images/close.svg"/>
      <div class="container">
        <div class="form-wrapper" v-if="!$parent.successMessage">
          <div class="title">Are you sure?</div>
          <div class="price title">TOTAL: {{$parent.optionsTotalPrice}} {{$parent.currency}}</div>
          <label>
            <div class="desc"><span class="red">*</span> Nickname:</div>
            <input type="text" placeholder="Nickname" v-model="nickname"/>
          </label>
          <button :class="['button', {'disabled': !nickname}]" @click="$parent.buyProducts">Buy</button>
          <transition name="fade">
            <div class="desc red" v-if="$parent.error">{{$parent.error}}</div>
          </transition>
        </div>
        <transition name="fade">
          <div v-if="$parent.successMessage">
            <div class="title green">{{$parent.successMessage}}</div>
            <button class="button" @click="$parent.closeReadyModalProducts">Ok</button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReadyModalProducts',
  props: [],
  components: {
  },
  data: function() {
    return {
      nickname: ''
    }
  },
  watch: {
    nickname() {
      this.$emit('nicknameChange', this.nickname)
    }
  },
  mounted: function() {
    this.nickname = this.$parent.nickname;
  },
  methods: {
   
  }
}
</script>