<template>
  <main class="main profile-page page-inside">  <!-- v-if="$parent.isAuth" -->
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">
            Profile
          </div>
          <div class="info">
            <div class="info-card">
              <div class="icon">
                <img src="./../images/user.svg" class="img"/>
              </div>
              <div class="text">
                <div class="title">{{name}} {{surname}}</div>
                <div class="desc">{{email}}</div>
              </div>
            </div>
            <div class="info-card">
              <div class="desc">My balance:</div>
              <div class="title">{{balance}} {{$parent.currency}}</div>
              <button class="button small" @click="$parent.openTopUpModal">Top up</button>
            </div>
          </div>
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper left">
                <div class="nav">
                  <div class="item">
                    <router-link class="desc" to="/profile/my-islands">My islands</router-link>
                  </div>
                  <div class="item">
                    <router-link class="desc" to="/profile/contact-info">Contact Information</router-link>
                  </div>
                  <div class="item">
                    <router-link class="desc" to="/profile/deposit-history">Deposit history</router-link>
                  </div>
                  <div class="item">
                    <router-link class="desc" to="/profile/order-history">Order history</router-link>
                  </div>
                  <div class="item">
                    <router-link class="desc" to="/profile/buy-island">Buy island</router-link>
                  </div>
                  <div class="item">
                    <a class="desc" @click="$parent.logout">Log out</a>
                  </div>
                </div>
              </div>
              <div class="wrapper right price-block price-block-buy history-block"  v-if="$route.params.content == 'deposit-history'">
                <div class="title">Deposit history</div>
                <div class="table-container">
                  <table>
                    <thead>
                      <tr>
                        <td class="title">Date</td>
                        <td class="title">Status</td>
                        <td class="title">Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in $parent.depositHistory" :key="i">
                        <td class="desc">{{$parent.formatDate(item.created_at)}}</td>
                        <td class="desc">{{item.status}}</td>
                        <td class="desc">{{item.sum}} {{$parent.currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="wrapper right price-block price-block-buy history-block"  v-if="$route.params.content == 'order-history'">
                <div class="title">Order history</div>
                <div class="table-container">
                  <table class="order-table">
                    <thead>
                      <tr>
                        <td class="title">Date</td>
                        <td class="title">Title</td>
                        <td class="title">Status</td>
                        <td class="title">Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in $parent.orderHistory" :key="i">
                        <td class="desc">{{$parent.formatDate(item.created_at)}}</td>
                        <td class="desc">{{item.title}}</td>
                        <td class="desc">{{item.status}}</td>
                        <td class="desc">{{item.sum}} {{$parent.currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="wrapper right price-block price-block-buy"  v-if="$route.params.content == 'buy-island'">
                <div class="our-price">
                  <div class="left">
                    <div class="title">Buy island</div>
                  </div>
                  <div class="right">
                    <multiselect v-model="activeIsland" :options="$parent.islands" @select="changeIslandToBuy" placeholder="PRICING">
                      <template slot="singleLabel" slot-scope="props">
                        <div class="desc">{{ props.option.title }}</div>
                        <div class="desc">{{ props.option.dimensions }}</div>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="flex">
                          <div class="left">
                            <div class="desc">{{ props.option.title }}</div>
                            <div class="desc">{{ props.option.dimensions }}</div>
                          </div>
                          <div class="right">
                            <div class="desc">{{ props.option.price }}</div>
                          </div>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="total">
                  <div class="flex">
                    <div class="desc">Total:</div>
                    <div v-if="Object.keys($parent.islandToBuy).length !== 0" class="price title"> {{$parent.islandToBuy.price}} {{$parent.currency}}</div>
                  </div>
                  <div @click="$parent.openReadyModal" :class="['button', {'disabled': Object.keys($parent.islandToBuy).length === 0}]">Buy</div>
                </div>
              </div>
              <div class="wrapper right price-block my-islands" v-if="$route.params.content == 'my-islands'">
                <div class="title small" v-if="!$parent.myIslands.length">You don't have any islands yet! Buy one, or add an existing from your friends!</div>
                <div class="list" v-if="$parent.myIslands.length">
                  <div class="item" v-for="(item, i) in $parent.myIslands" :key="i">
                    <ProductCard @buyOptions="buyOptions" :item="item" :currency="$parent.currency"/>
                  </div>
                </div>
                <div class="buttons">
                  <button class="button" @click="$parent.openAddExistingIslandModal">Add existing island</button>
                  <router-link to="/profile/buy-island" class="button">Buy island</router-link>
                </div>
              </div>
              <div class="wrapper right price-block price-block-buy buy-options-block"  v-if="$route.params.content == 'buy-options'">
                <div class="preview">
                  <div class="title">
                    <span>{{$parent.islandToBuyOptions.title}}</span>
                  </div>
                  <div class="desc">
                    <span>{{$parent.islandToBuyOptions.dimensions}}</span>
                  </div>
                </div>
                <div class="title" v-if="$parent.islandToBuyOptions.title">Buy options</div>
                <div class="checkbox-list-container">
                  <div class="checkbox-list" v-if="$parent.chosenIslandProducts"> 
                    <template v-for="(item, i) in $parent.chosenIslandProducts" >
                      <label class="chekbox-label-wrapper purchased" v-if="item.purchased" :key="i">
                        <input :value="item" :id="item.title" type="checkbox" v-model="optionsToBuy"/>
                        <div class="checkbox"></div>
                        <img :src="$parent.imgDomain + item.image_url" class="img"/>
                        <div class="text">
                          <div class="desc">{{item.title}}</div>
                          <div class="price title">{{item.price}} {{$parent.currency}}</div>
                        </div>
                      </label>
                      <label class="chekbox-label-wrapper" v-if="!item.purchased" :key="i">
                        <input :value="item" :id="item.title" type="checkbox" v-model="optionsToBuy"/>
                        <div class="checkbox"></div>
                        <img :src="$parent.imgDomain + item.image_url" class="img"/>
                        <div class="text">
                          <div class="desc">{{item.title}}</div>
                          <div class="price title">{{item.price}} {{$parent.currency}}</div>
                        </div>
                      </label>
                    </template>
                  </div>
                </div>
                <div v-if="optionsTotal" class="total-container">
                  <div class="total">
                    <div class="left">
                      <router-link to="/profile/my-islands" class="button">CANCEL</router-link>
                    </div>
                    <div class="center">
                      <div class="desc">Total:</div>
                      <div  class="price title"> {{optionsTotal}} {{$parent.currency}}</div>
                    </div>
                    <div class="right">
                      <button :class="['button', {'disabled': !optionsToBuy.length}]"  @click="$parent.openReadyModalProducts">UPDATE</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contact-form modal" v-if="$route.params.content == 'contact-info'">
            <div class="contact-form-wrapper">
              <div class="card">
                <div class="title">Contact information</div>
                <label>
                  <div class="desc"><span class="red">*</span> First name:</div>
                  <input type="text" placeholder="First name" v-model="name"/>
                </label>
                <label>
                  <div class="desc"><span class="red">*</span> E-mail:</div>
                  <input type="email" placeholder="E-mail" v-model="email"/>
                </label>
                <label>
                  <div class="desc"><span class="red">*</span> Last name:</div>
                  <input type="text" placeholder="Last name" v-model="surname"/>
                </label>
                <label>
                  <div class="desc"><span class="red">*</span> Phone:</div>
                  <input type="tel" placeholder="Phone" v-model="phone"/>
                </label>
                <label>
                  <div class="desc"><span class="red">*</span> Nickname on Minecraft server:</div>
                  <input type="text" placeholder="Nickname on Minecraft server" v-model="nickname"/>
                </label>
                <div class="button-container button-container-first">
                  <button class="button" @click="updateProfileData">
                    <span>Update</span>
                  </button>
                  <transition name="fade">
                    <div class="desc red" v-if="profileUpdateErrorMessaage">{{profileUpdateErrorMessaage}}</div>
                  </transition>
                  <transition name="fade">
                    <div class="desc green" v-if="profileUpdateSuccessMessaage">{{profileUpdateSuccessMessaage}}</div>
                  </transition>
                </div>
              </div>
              <div class="card">
                <div class="title">Change Password</div>
                <label>
                  <div class="desc"><span class="red">*</span> Current password:</div>
                  <input type="text" placeholder="Current password" v-model="curPass"/>
                </label>
                <label>
                  <div class="desc"><span class="red">*</span> New password:</div>
                  <input type="text" placeholder="New password" v-model="newPass"/>
                </label>
                <label>
                  <div class="desc"><span class="red">*</span> Confirm password:</div>
                  <input type="text" placeholder="Confirm password" v-model="repeatPass"/>
                </label>
                <div class="button-container">
                  <button class="button" @click="updatePassword">
                    <span>Update</span>
                  </button>
                  <transition name="fade">
                    <div class="desc red" v-if="passUpdateErrorMessaage">{{passUpdateErrorMessaage}}</div>
                  </transition>
                  <transition name="fade">
                    <div class="desc green" v-if="passUpdateSuccessMessaage">{{passUpdateSuccessMessaage}}</div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ProductCard from '../components/ProductCard.vue';
export default {
  name: 'Profile',
  props: ['optionsToBuyFinal', 'totalBalance', 'zeroOptionsChosen'],
  components: {
    ProductCard,
    Multiselect
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      nickname: '',
      balance: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      optionsToBuy: [],
      optionsTotal: '',
      activeIsland: {}
      
    }
  },
  watch: {
    optionsToBuy() {
      this.optionsTotal = null;
      for (let i = 0; i < this.optionsToBuy.length; i++) {
       this.optionsTotal +=  this.stringToNumberWithCommas(this.optionsToBuy[i].price)
      }
      this.$emit('optionsTotalPriceChange', this.optionsTotal)
      this.$emit('optionsToBuyFinalChange', this.optionsToBuy)
    },
    optionsToBuyFinal() {
      if (!this.optionsToBuyFinal.length) {
        this.optionsTotal = '';
      }
    },
    zeroOptionsChosen() {
      if (this.zeroOptionsChosen) {
        this.optionsToBuy = [];
      }  
    },
    totalBalance() {
      this.balance = this.totalBalance;
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
    .then((res) => {
        this.balance = res.data.balance;
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
        this.nickname = res.data.nickname;
    })
    .catch(() => {
      
    })
    if (Object.keys(this.$parent.islandToBuy).length !== 0) {
      this.activeIsland = this.$parent.islandToBuy;
    }
  },
  methods: {
    stringToNumberWithCommas(str) {
      let stringWithoutCommas = str.replace(/,/g, '');
      let number = parseFloat(stringWithoutCommas);
      return number;
    },
    openOfferDetailsModal() {
      this.$emit('openOfferDetailsModal')
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname,
        "nickname": this.nickname
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateErrorMessaage = '';
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    buyOptions(item) {
      this.$emit('changeIslandToBuyOptions', item);
      this.$router.push({path: '/profile/buy-options'});
    },
    changeIslandToBuy() {
      this.$emit('changeIslandToBuy', this.activeIsland);
    },
    
  }
}
</script>