<template>
	<div class="item-wrapper">
		<div class="preview" @click="buyOptions(item)">
			<div class="title">
				<span>{{item.title}}</span>
			</div>
			<div class="desc">
				<span>{{item.dimensions}}</span>
			</div>
		</div>
		<div class="host">
			<div class="desc small" ref="textToCopy" v-if="item.host">{{item.host}} ({{item.port}}) </div>
			<img @click="copyText" src="./../images/copy.svg" class="img"/>
			<transition name="fade">
				<div v-if="copySuccessMessage" class="desc copied">{{copySuccessMessage}}</div>
			</transition>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ProductCard',
	props: ['item', 'currency'],
	data: function() {
		return {
			copySuccessMessage: ''
		}
	},
	mounted() {
	},
	methods: {
		buyOptions(item) {
			console.log(item)
			this.$emit('buyOptions', item);
			this.$router.push({path: '/profile/buy-options/' + item.id});
		},
		copyText() {
			var textToCopy = this.$refs.textToCopy.innerText;

			if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
				navigator.clipboard.writeText(textToCopy)
				.then(() => {
					console.log('Text copied to clipboard');
					let self = this;
					this.copySuccessMessage = 'Copied';
					setTimeout(()=> {
						self.copySuccessMessage = '';
					}, 1000)
				})
				.catch((error) => {
					console.error('Unable to copy text: ', error);
				});
			} else {
				var textArea = document.createElement('textarea');
				textArea.value = textToCopy;
				textArea.style.position = 'fixed';
				document.body.appendChild(textArea);
				textArea.focus();
				textArea.select();

				try {
				var successful = document.execCommand('copy');
				var msg = successful ? 'Text copied to clipboard' : 'Unable to copy text';
				console.log(msg);
				let self = this;
				this.copySuccessMessage = 'Copied';
				setTimeout(()=> {
					self.copySuccessMessage = '';
				}, 1000)
				} catch (error) {
				console.error('Unable to copy text: ', error);
				}

				document.body.removeChild(textArea);
			}
		}
	},
}
</script>
