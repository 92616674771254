<template>
  <main class="main page-inside text-page contact-us-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">
            Contact Us
          </div>
          <div class="container">
            <div class="content">
              <label>
                <div class="desc">Name:</div>
                <input type="text" placeholder="Name" v-model="name"/>
              </label>
              <label>
                <div class="desc">Email:</div>
                <input type="text" placeholder="Email" v-model="email"/>
              </label>
              <label>
                <div class="desc">Message</div>
                <textarea placeholder="Message" v-model="message"></textarea>
              </label>
              <button class="button" @click="submit">Send</button>
              <transition name="fade">
                <div v-if="$parent.successMessage" class="title green">{{$parent.successMessage}}</div>
              </transition>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
              </transition>
            </div>
            <div class="info desc">
              UnioMediaAgency OU <br/><br/>
              Reg.n. - 14571568<br/><br/>
              Address: Harju maakond, Tallinn, Kesklinna linnaosa, Vesivärava tn 50-201<br/><br/>
              Zip code: 10152<br/><br/>
              E-mail: {{ $parent.settings.support_email }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'ContactUs',
  props: [],
  components: {
    
  },
  data: function() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    },
  }
}
</script>